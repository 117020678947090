/* Style the navbar */

  .sticky {
    position: fixed;
    top: 0;
    max-height: 20px;
    width: 100%;
    z-index: 100000;
  }
  .sticky-primary{
    position: fixed;
    top: 40px;
    width: 100%;
    z-index: 100000;
  }

  .nav-primary-content{
    display: flex;
    align-items: center;
  }

  .nav-primary-content > .nav-link{
    color:white !important
  }